/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// import "@rails/actiontext";

import LazyLoad from "vanilla-lazyload";

import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";

import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import "@fullcalendar/list/main.css";
import "@fullcalendar/timeline/main.css";

import "font-awesome/css/font-awesome";

import "typeface-roboto/index.css";
import "typeface-roboto-condensed/index.css";

// import "trix/dist/trix.css";

function display_eventsource_for(calendar) {
  var k, last;
  last = new Date();
  var all = calendar.getEventSources();
  for (var i = 0; i < all.length; i++) {
    all[i].remove();
  }
  console.log("displaying calendar data", eventsource_for);
  for (k in eventsource_for) {
    if (eventsource_for.hasOwnProperty(k)) {
      if ($("#" + k).is(":checked")) {
        console.log("found that this is checked: ", k);
        calendar.addEventSource(eventsource_for[k]);
      }
    }
  }
  for (let i in fixed_moduls_and_courses) {
    if (fixed_moduls_and_courses.hasOwnProperty(i)) {
      k = fixed_moduls_and_courses[i];
      if (!calendar.getEventSourceById(k)) {
        calendar.addEventSource(eventsource_for[k]);
      }
    }
  }

  for (let i in recommended_courses) {
    if (recommended_courses.hasOwnProperty(i)) {
      let k = recommended_courses[i];
      if (eventsource_for[k] && !calendar.getEventSourceById(k)) {
        calendar.addEventSource(eventsource_for[k]);
      }
    }
  }
}

function apply_filters(calendar, data) {
  fixed_moduls_and_courses = data;
  display_eventsource_for(calendar);
}

function height_for_mode(mode) {
  if (mode == "small") {
    return 200;
  } else {
    return 600;
  }
}

function buttons_for_mode(mode) {
  if (mode == "small") {
    return "dayGridWeek,dayGridDitact";
  } else {
    return "timeGridWeek,timeGridDitact";
  }
}

function default_for_mode(mode) {
  if (mode == "small") {
    return "dayGridDitact";
  } else {
    return "timeGridDitact";
  }
}

function recalc() {
  var target = document.querySelector(".gesamt.kosten");
  if (!target) return;
  var fees = document.querySelectorAll(
    ".edit_enroll input[type=checkbox]:checked"
  );
  var sum = 0;
  for (var i = 0; i < fees.length; i++) {
    var node = fees[i];
    sum += parseFloat(node.dataset.fee);
  }
  target.innerHTML = sum;
  console.log("doing recalc: ", sum);
}

//
// ======================= on load ==================0
//

window.addEventListener("load", function () {
  recalc();
  var lazyLoadInstance = new LazyLoad({
    elements_selector: ".lazy",
    // ... more custom settings?
    // see https://github.com/verlok/lazyload
  });
  // page is now ready, initialize the calendar...

  var calendar_node = document.getElementById("calendar");
  console.log("display calendar in", calendar_node);

  if (calendar_node) {
    calendar = new Calendar(calendar_node, {
      plugins: [timeGridPlugin, dayGridPlugin],
      timeZone: "Europe/Vienna",
      views: {
        timeGridDitact: {
          type: "timeGrid",
          duration: { days: 13 },
          buttonText: "ganze ditact",
        },
        dayGridDitact: {
          type: "dayGridWeek",
          duration: { days: 13 },
          buttonText: "ganze ditact",
        },
      },
      defaultView: default_for_mode(mode),
      buttonText: {
        today: "Heute",
        month: "Monat",
        week: "Eine Woche",
        day: "Tag",
        list: "Liste",
      },
      height: height_for_mode(mode),
      allDaySlot: false,
      hiddenDays: [0],
      columnHeaderFormat: {
        weekday: "short",
        month: "numeric",
        day: "numeric",
      },
      slotLabelFormat: {
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: true,
        meridiem: "short",
        hourCycle: "h24",
      },
      eventTimeFormat: {
        hour: "numeric",
        minute: "2-digit",
        omitZeroMinute: true,
        meridiem: "short",
        hourCycle: "h24",
      },
      firstDay: 1, // Monday
      date: calendar_startday,
      minTime: "08:00:00",
      maxTime: "20:00:00",
      header: {
        left: "prev,next",
        center: "",
        right: buttons_for_mode(mode),
      },
    });

    calendar.render();
    calendar.gotoDate(calendar_endday);
    display_eventsource_for(calendar);

    var myNodeList = document.querySelectorAll(
      ".edit_enroll input[type=checkbox]"
    );

    for (var i = 0; i < myNodeList.length; i++) {
      var checkbox = myNodeList[i];
      var k = checkbox.id;
      checkbox.addEventListener("change", function (event) {
        if (event.target.checked) {
          console.log("this is checkbox ", this, " now showing ", this.id);
          calendar.addEventSource(eventsource_for[this.id]);
        } else {
          console.log("this is checkbox ", this, " now removing ", this.id);
          calendar.getEventSourceById(this.id).remove();
        }
        recalc();
      });
    }
  }

  var grid_node = document.getElementById("grid");

  if (grid_node) {
    console.log("displaying calendar grid", grid_data);

    grid_data.plugins = [timeGridPlugin, dayGridPlugin];

    let grid = new Calendar(grid_node, grid_data);
    grid.render();
    grid.gotoDate(grid_data.date);
  }
});
